<template>
  <div class="confirm-wrapper" v-if="visible">
    <h2>{{ title }}</h2>
    <p>{{ text }}</p>
    <div class="confirm-buttons">
      <button class="confirm-button cofirm-button-cancel" @click="hide">Cancel</button>
      <button class="confirm-button confirm-button-confirm" @click="confirm">Confirm</button>
    </div>
  </div>
</template>

<script>
// we must import our Confirm plugin instance
// because it contains reference to our Eventbus
import Confirm from './confirm.js';

export default {
  data() {
    return {
      visible: false, 
      title: '',
      text: '',
      // adding callback function variable
      onConfirm: {}
    }
  },
  methods: {
    hide() {
      // method for closing modal
      this.visible = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if(typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm();
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      // setting title and text
      this.title = params.title;
      this.text = params.text;
      // setting callback function
      this.onConfirm = params.onConfirm;
    }
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Confirm.EventBus.$on('show', (params) => {
      this.show(params)
    })
  }
}
</script>

<style>
.confirm-wrapper {
	position: fixed;
	top: 50%;
	z-index: 99;
	background-color: #3b4b64;
	padding: 20px;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #f5f5f5;
	border-radius: 2px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.confirm-buttons {
  bottom: 0;
  left: 0;
  right: 0; 
  display: flex;
}
.confirm-button {
	margin-right: 10px;
	background: #f5f5f5;
	border: none;
	padding: 5px 25px;
}

.confirm-button-cancel {
  
}

.confirm-button-confirm {
  
}
</style>
import Vue from "vue"
import store from "../store.js"
import Router from "vue-router"

// Containers
const TheContainer = () => import("@/containers/TheContainer")
const AuthContainer = () => import("@/containers/AuthContainer")
const FrontPageContainer = () => import("@/containers/FrontPage/Container")
const CustomerBookingContainer = () =>
  import("@/containers/CustomerBooking/Container")

// Frontpage
const Home = () => import("@/views/frontpage/Home")
const Cases = () => import("@/views/frontpage/Cases")
const CaseDetail = () => import("@/views/frontpage/CaseDetail")
const ArticlesFrontPage = () => import("@/views/frontpage/Articles")
const ArticleDetailFrontPage = () => import("@/views/frontpage/ArticleDetail")
const Contact = () => import("@/views/frontpage/Contact")

// Auth
const Login = () => import("@/views/auth/Login")
const ForgotPassword = () => import("@/views/auth/ForgotPassword")
const ResetPassword = () => import("@/views/auth/ResetPassword")
const CreatePassword = () => import("@/views/auth/CreatePassword")

// Views
const Dashboard = () => import("@/views/dashboard-admin/Dashboard")

// Locations
const Locations = () => import("@/views/locations/Locations")
const LocationCreate = () => import("@/views/locations/Create")
const LocationDetail = () => import("@/views/locations/Detail")

// Customers
const Customers = () => import("@/views/customers/Customers")
const CustomerCreate = () => import("@/views/customers/Create")
const CustomerDetail = () => import("@/views/customers/Detail")

// Bookings
const Bookings = () => import("@/views/bookings/Bookings")
const BookingCreate = () => import("@/views/bookings/Create")
const BookingDetail = () => import("@/views/bookings/Detail")

// Products
const Products = () => import("@/views/products/Products")
const ProductCreate = () => import("@/views/products/Create")
const ProductDetail = () => import("@/views/products/Detail")

// Parts
const Parts = () => import("@/views/parts/Parts")
const PartCreate = () => import("@/views/parts/Create")
const PartDetail = () => import("@/views/parts/Detail")

// Part Categories
const PartCategories = () => import("@/views/part-categories/PartCategories")
const PartCategoryCreate = () => import("@/views/part-categories/Create")
const PartCategoryDetail = () => import("@/views/part-categories/Detail")

// Defects
const Defects = () => import("@/views/defects/Defects")
const DefectCreate = () => import("@/views/defects/Create")
const DefectDetail = () => import("@/views/defects/Detail")

// Invoices
const Invoices = () => import("@/views/invoices/Invoices")
const InvoiceCreate = () => import("@/views/invoices/Create")
const InvoiceDetail = () => import("@/views/invoices/Detail")

// Users
const Users = () => import("@/views/users/Users")
const UserCreate = () => import("@/views/users/Create")
const UserDetail = () => import("@/views/users/Detail")

// Projects
const Projects = () => import("@/views/projects/Projects")
const ProjectCreate = () => import("@/views/projects/Create")
const ProjectDetail = () => import("@/views/projects/Detail")

// Articles
const Articles = () => import("@/views/articles/Articles")
const ArticleCreate = () => import("@/views/articles/Create")
const ArticleDetail = () => import("@/views/articles/Detail")

// Customer Booking
const CustomerBookingQR = () => import("@/views/customer-booking/QR")
const CustomerBookingRequest = () => import("@/views/customer-booking/Request")

// Views - Pages
const Page404 = () => import("@/views/error-pages/Page404")
const Page500 = () => import("@/views/error-pages/Page500")

Vue.use(Router)

// Router object
let router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

// Check routes with meta requires Auth
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next("/auth/login")
    }
    console.log("is logged in")
    return next()
  } else {
    next()
  }
})

// Route configurations
function configRoutes() {
  return [
    {
      path: "*",
      name: "not-found",
      component: Page404,
    },
    {
      path: "/",
      redirect: "/error-pages/404",
      name: "FrontPage",
      component: FrontPageContainer,
      children: [
        {
          path: "",
          name: "Home",
          component: Home,
        },
        {
          path: "cases",
          name: "Cases",
          component: Cases,
        },
        {
          path: "cases/:id",
          name: "CaseDetail",
          component: CaseDetail,
        },
        {
          path: "articles",
          name: "Articles",
          component: ArticlesFrontPage,
        },
        {
          path: "articles/:id",
          name: "ArticleDetail",
          component: ArticleDetailFrontPage,
        },
        {
          path: "contact",
          name: "Contact",
          component: Contact,
        },
      ],
    },
    {
      path: "/auth",
      redirect: "/error-pages/404",
      name: "Auth",
      component: AuthContainer,
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "forgot-password",
          name: "Forgot Password",
          component: ForgotPassword,
        },
        {
          path: "reset-password/:code",
          name: "Reset Password",
          component: ResetPassword,
        },
        {
          path: "create-password/:code",
          name: "Create Password",
          component: CreatePassword,
        },
      ],
    },
    {
      path: "/manager",
      redirect: "/dashboard",
      name: "Dashboard",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "dashboard/admin",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "locations",
          name: "Locations",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Locations,
            },
            {
              path: "create",
              name: "Create a Location",
              component: LocationCreate,
            },
            {
              path: ":id",
              name: "Details of a Location",
              component: LocationDetail,
            },
          ],
        },
        {
          path: "customers",
          name: "Customers",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Customers,
            },
            {
              path: "create",
              name: "Create a Customer",
              component: CustomerCreate,
            },
            {
              path: ":id",
              name: "Details of a Customer",
              component: CustomerDetail,
            },
          ],
        },
        {
          path: "users",
          name: "Users",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Users,
            },
            {
              path: "create",
              name: "Create a User",
              component: UserCreate,
            },
            {
              path: ":id",
              name: "Details of a User",
              component: UserDetail,
            },
          ],
        },
        {
          path: "bookings",
          name: "Bookings",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Bookings,
            },
            {
              path: "create",
              name: "Create a Booking",
              component: BookingCreate,
            },
            {
              path: ":id",
              name: "Details of a Booking",
              component: BookingDetail,
            },
          ],
        },
        {
          path: "products",
          name: "Products",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Products,
            },
            {
              path: "create",
              name: "Create a Product",
              component: ProductCreate,
            },
            {
              path: ":id",
              name: "Details of a Product",
              component: ProductDetail,
            },
          ],
        },

        {
          path: "parts",
          name: "Parts",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Parts,
            },
            {
              path: "create",
              name: "Create a Part",
              component: PartCreate,
            },
            {
              path: ":id",
              name: "Details of a Part",
              component: PartDetail,
            },
          ],
        },

        {
          path: "part-categories",
          name: "Part Categories",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: PartCategories,
            },
            {
              path: "create",
              name: "Create a Part Category",
              component: PartCategoryCreate,
            },
            {
              path: ":id",
              name: "Details of a Part Category",
              component: PartCategoryDetail,
            },
          ],
        },

        {
          path: "defects",
          name: "Defects",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Defects,
            },
            {
              path: "create",
              name: "Create a Part",
              component: DefectCreate,
            },
            {
              path: ":id",
              name: "Details of a Defect",
              component: DefectDetail,
            },
          ],
        },

        {
          path: "invoices",
          name: "Invoices",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Invoices,
            },
            {
              path: "create",
              name: "Create an Invoice",
              component: InvoiceCreate,
            },
            {
              path: ":id",
              name: "Details of an Invoice",
              component: InvoiceDetail,
            },
          ],
        },
        {
          path: "projects",
          name: "Projects",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Projects,
            },
            {
              path: "create",
              name: "Create a Project",
              component: ProjectCreate,
            },
            {
              path: ":id",
              name: "Details of a Project",
              component: ProjectDetail,
            },
          ],
        },
        {
          path: "articles",
          name: "Articles",
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Overview",
              component: Articles,
            },
            {
              path: "create",
              name: "Create a Project",
              component: ArticleCreate,
            },
            {
              path: ":id",
              name: "Details of a Project",
              component: ArticleDetail,
            },
          ],
        },
      ],
    },

    {
      path: "/customer-booking",
      redirect: "/error-pages/404",
      name: "CustomerBooking",
      component: CustomerBookingContainer,
      children: [
        {
          path: ":id/qr",
          name: "Customer Booking QR",
          component: CustomerBookingQR,
        },
        {
          path: ":id",
          name: "Customer Booking Request",
          component: CustomerBookingRequest,
        },
      ],
    },

    {
      path: "/pages",
      redirect: "/error-pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view")
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
  ]
}

export default router

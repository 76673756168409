import Base from './base.js'

export default class Auth extends Base {

	constructor() {
		super()
		this.grant_type = 'password'
		this.client_id = process.env.VUE_APP_API_CLIENT_ID
		this.client_secret = process.env.VUE_APP_API_CLIENT_SECRET
	}

	token(username, password) {
		console.log('auth - token')
	 	this.route = '/api/auth'
		// Process the data
		let data = {
			'grant_type': this.grant_type,
			'client_id': this.client_id,
			'client_secret': this.client_secret,
			'username': username,
			'password': password
		}

		let self = this
		return new Promise(function(resolve, reject) {
			console.log('auth - token - promise')
			console.log(self.Axios)
			self.Axios({url: self.route, data: data, method: 'POST'})
			.then((response) => {
				console.log('auth - token - promise - success')
				// Resolve successfully
				resolve(response.data.access_token)
			})
			.catch((response) => {
				console.log('auth - token - promise - error')
				console.log(response)
				reject(false)
			});
		})
	}

	profile() {
		this.route = '/api/user/profile'
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route, method: 'GET'})
			.then((response) => {
				resolve(response.data)
			})
			.catch((response) => {
				reject(false)
			});
		})
	}

	createPassword(code, password) {
		this.route = '/api/auth'
		let data = {'code': code, 'password': password}
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + '/create-password', data: data, method: 'POST'})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				console.log('resp error: ')
				console.log(error.response.data)
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

	requestPasswordResetLink(email) {
		this.route = '/api/auth'
		let data = {'email': email}
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + '/request-password-reset-link', data: data, method: 'POST'})
			.then((response) => {
				resolve(response.data)
			})
			.catch((response) => {
				reject(false)
			});
		})
	}

	resetPassword(code, password) {
		this.route = '/api/auth'
		let data = {'code': code, 'password': password}
		let self = this
		return new Promise(function(resolve, reject) {
			self.Axios({url: self.route + '/reset-password', data: data, method: 'POST'})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				console.log('resp error: ')
				console.log(error.response.data)
				reject(self.parseErrorResponses(error.response.data))
			});
		})
	}

}
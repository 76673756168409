import Vue from "vue"
import Vuex from "vuex"
import store from "./store"
import App from "./App"
import router from "./router"
import CoreuiVue from "@coreui/vue"
import { iconsSet as icons } from "./assets/icons/icons.js"
import { Settings } from "luxon"
import Confirm from "./plugins/Confirm/confirm.js"
import Alert from "./plugins/Alert/alert.js"
import filters from "./filters"
import CKEditor from "@ckeditor/ckeditor5-vue"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

Vue.component("v-select", vSelect)

Vue.use(CKEditor)
Vue.use(Confirm)
Vue.use(Alert)
Vue.use(filters)
Vue.use(CoreuiVue)

Vue.use(Vuex)

Vue.config.performance = true

Settings.defaultLocale = "nl"
Settings.defaultZoneName = "UTC"

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
})

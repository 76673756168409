import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from "@coreui/icons";
import {
  cilArrowRight,
  cilArrowCircleBottom,
  cilBan,
  cilBasket,
  cilBell,
  cilBellExclamation,
  cilCalculator,
  cilCalendar,
  cilClock,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilFile,
  cilDelete,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilHamburgerMenu,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMenu,
  cilMoney,
  cilMoon,
  cilOptions,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPin,
  cilPlaylistAdd,
  cilPlus,
  cilPuzzle,
  cilScreenSmartphone,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
} from "@coreui/icons";

export const iconsSet = Object.assign(
  {
    cilArrowRight,
    cilArrowCircleBottom,
    cilBan,
    cilBasket,
    cilBell,
    cilBellExclamation,
    cilCalculator,
    cilCalendar,
    cilClock,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDelete,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilHamburgerMenu,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMenu,
    cilMoney,
    cilMoon,
    cilOptions,
    cilPaperPlane,
    cilPencil,
    cilPin,
    cilPlaylistAdd,
    cilPlus,
    cilPeople,
    cilPuzzle,
    cilScreenSmartphone,
    cilSettings,
    cilShieldAlt,
    cilScreenSmartphone,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilTrash,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  }
);

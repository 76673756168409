import Axios from "axios"

class Base {
  constructor() {
    let self = this

    this.route = ""

    // Set config defaults when creating the instance
    this.Axios = Axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    })
    this.Axios.defaults.headers.common.Accept = "application/json"

    /*
     * The interceptor here ensures that we check for the token in local storage every time a request is made.
     *If there is, add it to the request
     */
    this.Axios.interceptors.request.use((config) => {
      let token = localStorage.getItem("token")
      if (token) {
        // Check for authorization
        config.headers.Authorization = "Bearer " + token
      } else {
        config.headers.Authorization = ""
      }
      return config
    })

    this.Axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        if (500 === error.response.status) {
          // Return custom error
          let customError = {
            response: {
              data: {
                errors: [
                  "There was an unknown error. Try again or contact the system administrator.",
                ],
              },
            },
          }
          return Promise.reject(customError)
        } else if (
          403 === error.response.status ||
          (401 === error.response.status && self.route != "/api/auth")
        ) {
          window.location.href = "/auth/login"
          // return Promise.reject(error);
        } else {
          return Promise.reject(error)
        }
      }
    )
  }

  /*
   * Parses error object into a single string
   */
  parseErrorResponses(errors) {
    console.log("parseErrorResponses:")
    console.log(errors)
    console.log(typeof errors)
    console.log(errors instanceof Array)

    var errorString = ""

    if (typeof errors === "string") {
      errorString = errors
    } else if (errors instanceof Array) {
      for (var i = 0; i < errors.length; i++) {
        errorString += "- " + errors[i] + "<br>"
      }
    } else if (Object.keys(errors).length > 0) {
      if ("message" in errors) {
        errorString = errors.message
      } else {
        for (var key in errors) {
          for (var i = 0; i < errors[key].length; i++) {
            errorString += "- " + errors[key][i] + "<br>"
          }
        }
      }
    } else {
      errorString = "undefined error occurred"
    }

    return errorString
  }
}

export default Base

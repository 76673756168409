import Vue from 'vue';
import Vuex from 'vuex';
import Auth from '/app/src/api/auth.js';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		status: '',
		loading: false,
		alert: {},
		notify: '',
		token: localStorage.getItem('token') || '',
		user: JSON.parse(localStorage.getItem('user')) || '',
		location: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).location : {},
	},
	mutations: {
		auth_request(state) {
			state.status = 'loading';
		},
		auth_success(state, payload) {
			console.log('payload:');
			console.log(payload);
			state.status = 'success';
			state.token = payload.token;
			state.user = payload.user;
			state.location = payload.user.location;
		},
		auth_error(state) {
			state.status = 'error';
		},
		logout(state) {
			state = {};
		},
		setLoading(state, payload) {
			state.loading = payload;
		},
		setAlert(state, payload) {
			state.alert = payload;
		},
		setLocation() {
			state.location = location;
		},
	},
	actions: {
		loading({ commit }) {
			commit('setLoading', true);
		},
		stopLoading({ commit }) {
			commit('setLoading', false);
		},
		alert({ commit }, payload) {
			commit('setAlert', payload);
		},
		clearAlert({ commit }) {
			commit('setAlert', {});
		},
		login({ commit }, data) {
			let auth = new Auth();

			return new Promise((resolve, reject) => {
				commit('auth_request');
				console.log('store - auth');
				auth
					.token(data.email, data.password)
					.then(token => {
						console.log('store - auth - token - then');

						localStorage.setItem('token', token);

						auth
							.profile()
							.then(user => {
								console.log('store - auth - token - then');
								// Store stringified in local storage
								localStorage.setItem('user', JSON.stringify(user));

								// Commit to vuex
								commit('auth_success', {
									token: token,
									user: user,
								});
								resolve(token);
							})
							.catch(error => {
								console.log('store - auth - profile - catch');
								console.log(error);
								commit('auth_error', error);
								localStorage.removeItem('token');
								localStorage.removeItem('user');
								reject(error);
							});
					})
					.catch(error => {
						console.log('store - auth - token - catch');
						commit('auth_error', error);
						reject(error);
					});
			});
		},

		logout({ commit }) {
			return new Promise((resolve, reject) => {
				commit('logout');
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				localStorage.removeItem('location');
				resolve();
			});
		},
	},

	getters: {
		isLoggedIn: state => !!state.token,
		isAdmin: state => state.user.type === 'admin',
		isLocationElite: state => state.user.type === 'location_elite',
		isLocationAdmin: state => state.user.type === 'location_admin',
		isLocationRegular: state => state.user.type === 'location_regular',
		authStatus: state => state.status,
		user: state => state.user,
		location: state => state.location,
		alert: alert => state.alert,
		loading: loading => state.loading,
	},
});

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

  .float-right {
    float: right;
  }

  .container-fluid {
    margin-bottom: 20px;
  }

  .card-header h4 {
    float: left;
  }

  .btn .c-icon {
    margin-right: 5px;
    margin-top: 0;
  }
  
  .btn {
    margin-right: 5px;
  }

  .btn svg {
    margin-right: 5px;
  }

  .vdatetime-input {
    padding: 0.375rem 0.75rem;
    width: 100%;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
  }

  input:disabled {
    background-color: #d8dbe0 !important;
  }

  .card-header-actions button {
    margin-right: 5px;
  }

  .card-title {
    margin-bottom: 0;
  }

  .card-subtitle {
    font-size: 18px;
    font-weight: 500;
    padding-top: 8px;
  }


  .modal-primary .modal-content {
      border-color: #303c54 !important;
  }

  .modal-primary .modal-header {
      color: #fff;
      background-color: #303c54 !important;
  }
  
  .list-new-btn {
    margin-bottom: 20px;
  }

  .ck-editor__editable {
    min-height: 200px;
    max-height: 400px;
   }

   .clear-btn svg {
     margin-right: 0 !important; 
   }

  .location-return-time .vdatetime-input {
    width: auto !important;
  }

</style>

<template>
  <div class="alert-wrapper" v-if="visible">
    <CAlert
      :color="type"
      :show.sync="visible"
      class="alert-dismissible"
    >
    <span v-html="message"></span>
      <CButton
        class="position-absolute"
        color="secondary"
        style="right:10px;top: 50%;transform: translateY(-50%);"
        @click="visible = false"
      >
        Close
      </CButton>
    </CAlert>
  </div>
</template>

<script>

import Alert from './alert.js';

export default {
  data() {
    return {
      visible: false, 
      type: '',
      message: '',
    }
  },
  methods: {
    hide() {
      // method for closing modal
      this.visible = false;
    },
    show(params) {
      // making modal visible
      this.visible = true;
      // setting title and text
      this.type = params.type;
      this.message = params.message;
      window.scrollTo(0,0);
    }
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Alert.EventBus.$on('show', (params) => {
      this.show(params)
    })
  }
}
</script>

<style>

</style>